const routes = [
  // Home
  {
    path: "/",
    component: "Home",
  },
  // Header
  {
    path: "/download",
    component: "Download",
  },
  {
    path: "/career",
    component: "Career",
  },
  {
    path: "/contact",
    component: "Contact",
  },
  {
    path: "/register-business",
    component: "Business",
  },
  // Footer
  {
    path: "/about",
    component: "About",
  },
  {
    path: "/privacy",
    component: "Privacy",
  },
  {
    path: "/terms-and-conditions",
    component: "TermsAndConditions",
  },
  // Other
  {
    path: "/discover-stamp-cards",
    component: "DiscoverStampCards",
  },
];

export default routes;
