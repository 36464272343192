import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { link } from "fs";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    let navigate = useNavigate(); 
    const routeChange = (id: string) =>{ 
    navigate(id);
    setVisibility(false);
  }
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => routeChange("download")}>
          <Span>{t("header.download")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => routeChange("career")}>
          <Span>{t("header.career")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => routeChange("contact")}>
          <Span>{t("header.contact")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => routeChange("register-business")}
        >
          <Span>
            <Button>{t("header.registerYourBusiness")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between"> 
          <Row justify="start">
            <LogoContainer to="/" aria-label="homepage">
              <SvgIcon src="logo.svg" width="64px" height="64px" />
            </LogoContainer>
            <LogoContainer to="/" aria-label="homepage">
              <SvgIcon src="logo_text.svg" width="190px" height="64px" />
            </LogoContainer>
            <NotHidden>
              <MenuItem />
            </NotHidden>
            </Row>
            <Burger onClick={showDrawer}>
              <Outline />
            </Burger>
          </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
