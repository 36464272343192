import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import CookieConsent from "react-cookie-consent";
import { withTranslation } from "react-i18next";
import { createGlobalStyle } from "styled-components";
import "../style.css";

const Router = ({ t }: any) => {
  return (
    <Suspense fallback={null}>
      {createGlobalStyle}
      <Header />
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.accept")}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {t("cookies.disclaimer")} <a href="\privacy">{t("footer.privacy")}</a>.{" "}
      </CookieConsent>
      <Routes>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              Component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Routes>
      <Footer />
    </Suspense>
  );
};

export default withTranslation()(Router);
